.imageElement {
    @apply flex items-start gap-4;
}

.imageData {
    @apply flex flex-col gap-4;
}

.imageDataItem {
    @apply flex gap-2 items-center;
}

.copyButton {
    @apply w-6 h-6;
}