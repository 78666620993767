.page {
    @apply w-full h-full flex flex-col;
}

.header {
    @apply container mx-auto basis-16 h-16 py-3 flex-grow-0 flex-shrink-0 bg-white flex justify-between items-center;
}

.logo {
    @apply h-full;
}

.main {
    @apply flex-1 max-h-[calc(100%_-_4rem)];
}