.item {
    @apply relative p-4 w-auto cursor-pointer bg-white/50 my-4 text-gray-800 font-bold;
}

.active {
    @apply bg-white/80;
}

.editButton {
    @apply absolute right-4 inset-y-0 p-1 my-auto hover:bg-blue-100;
}