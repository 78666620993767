.activeSectionContainer {
    @apply flex-1 px-8 overflow-auto relative;
}

.contentForm {
    @apply my-8;
}

.buttons {
    @apply flex justify-between my-4;
}