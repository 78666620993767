.notesContainer {
    @apply my-8;
}

.notes {
    @apply flex flex-col gap-4 mt-2 mb-4;
}

.note {
    @apply relative border px-4 py-2 text-sm text-gray-800;
}

.userName {
    @apply absolute -bottom-2 right-4 bg-gray-100 text-gray-400 px-2;
}