.activePageContainer {
    @apply flex-1 relative py-8 flex border-gray-800 border-2 bg-gray-100;
}

.activePage {
    @apply w-96 flex flex-col overflow-auto;
}

.addSection {
    @apply w-full h-16 px-8 flex-shrink-0;
}

.addSectionButton {
    @apply bg-gray-400 w-full h-full;
}

.addingColumn {
    @apply my-4;
}

.addingSectionForm {
    @apply px-8;
}