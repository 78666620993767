.textArea {
    @apply border;
}

.content {
    @apply relative;
}

.copyButton {
    @apply absolute right-0 -top-6 h-6 w-6;
}