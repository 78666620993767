.imageGrid {
    @apply max-w-full flex gap-4 flex-wrap;
}

.item {
    @apply w-32 h-32 rounded-lg border shadow-sm cursor-pointer hover:shadow;
}

.item img {
    @apply rounded-lg w-full h-full object-cover;
}

.addingImageForm {
    @apply basis-full flex flex-col gap-2;
}

.addingImageForm input[type=text] {
    @apply border rounded px-2 py-1;
}

.addingImageForm button {
    @apply px-2 py-1;
}