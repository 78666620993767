.element {
    @apply flex flex-col py-2 my-2  pr-8 relative;
}

.addingElementForm {
    @apply w-64;
}

.operations {
    @apply absolute flex h-full flex-col justify-between w-6 -left-6 bg-gray-300 rounded-l-lg;
}

.operations button {
    @apply p-2 w-full flex-1 first-of-type:border-b;
}

.elementName {
    @apply font-bold;
}

.elementPrompt {
    @apply italic text-gray-500 text-sm mb-1;
}

.deleteButton {
    @apply absolute hidden top-0 h-full w-8 right-0 bg-red-300 text-white; 
}

.element:hover .deleteButton {
    @apply block;
}