.pageContainer {
    @apply relative;
}

.subPageButton {
    @apply absolute -right-8 inset-y-0 my-auto w-8 border border-gray-300;
}

.subPageContainer {
    @apply ml-8 mt-2;
}

.addPage {
    @apply relative p-4 w-auto cursor-pointer bg-white/50 my-4 text-gray-800 font-bold text-center;
}

.pageDragHandle {
    @apply hidden absolute w-6 h-12 bg-gray-500 rounded-l-lg -left-6 inset-y-0 my-auto flex-col justify-start items-center;
}

.pageContainer:hover .pageDragHandle {
    @apply flex;
}