.projectPage {
    @apply p-8;
}

.header {
    @apply text-2xl font-bold text-gray-900 mb-8;
}

.projectGrid {
    @apply flex flex-wrap gap-4 w-full;
}

.projectCard {
    @apply border rounded px-8 py-4 cursor-pointer flex flex-col;
}