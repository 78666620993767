.sectionContent {
    @apply bg-gray-200 border border-gray-600 w-full flex items-center justify-center gap-2 h-full;
}

.draft {
    @apply bg-blue-200;
}

.approved {
    @apply bg-blue-600 text-white;
}