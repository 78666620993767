.projectPalette {
    @apply mb-8;
}

.colorList {
    @apply flex flex-col gap-2;
}

.colorItem {
    @apply flex items-center gap-16;
}

.colorItemText {
    @apply w-48;
}

.colorSquare {
    @apply px-8 py-4 cursor-pointer rounded shadow-sm hover:shadow;
}