.componentForm {
    @apply flex flex-col gap-2;
}

.componentForm label {
    @apply font-bold;
}

.formInput {
    @apply border rounded my-1 px-4 py-2 w-full text-gray-900;
}

.formButtons {
    @apply flex justify-between gap-2 my-2;
}

.checkbox {
    @apply mb-4 flex justify-between;
}

.checkbox input[type=checkbox] {
    @apply w-6 h-6;
}

.button {
    @apply py-2 px-4;
}

.buttonPrimary {
    @apply button bg-blue-700 text-white font-bold rounded shadow-sm hover:shadow;
}

.buttonPrimary.loading {
    @apply bg-blue-900;
}

.buttonDanger {
    @apply button text-red-800 border-2 border-red-800 rounded bg-gray-100;
}

.buttonSecondary {
    @apply button font-bold text-gray-600 border-2 rounded border-blue-500;
}

.buttonLink {
    @apply button text-gray-600 underline hover:no-underline;
}

.headingSecondary {
    @apply text-2xl font-bold;
}

.headingTertiary {
    @apply text-lg font-semibold my-2 text-gray-700;
}