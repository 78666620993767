.section {
    @apply w-full h-24 px-8 flex relative flex-shrink-0;
}

.active {
    @apply font-bold;
}

.column {
    @apply flex-grow;
}

.sectionDragHandle {
    @apply flex absolute w-6 h-full bg-gray-300 rounded-l-lg left-2 inset-y-0 my-auto flex-col justify-between items-center;
}

.sectionDragHandle button {
    @apply w-full flex-1 p-2 first-of-type:border-b;
}

.deleteButton {
    @apply hidden absolute top-0 right-0 h-full w-8 bg-red-300;
}

.section:hover .deleteButton {
    @apply block;
}